import { AxiosError } from 'axios';
import { logout, refreshToken } from 'redux/slices/authSlice';
import { WASHMEN_CUSTOMER_API } from './config';
import { Store } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

// ERROR CODES
const SESSION_EXPIRED = 403.2;
const INVALID_AUTH_TOKEN = 403.1;
const UNAUTHORIZED_ERROR = 403;
const USER_INACTIVE = 403.4;

// TODO: properly support ts type of store;

const Interceptor = (Store: Store<any, any>) => {
  WASHMEN_CUSTOMER_API.interceptors.request.use(
    (conf) => {
      // you can add some information before send it.
      // conf.headers['Auth'] = 'some token'
      return conf;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  WASHMEN_CUSTOMER_API.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error: AxiosError) => {
      // You can handle error here and trigger warning message without get in the code inside
      if (error.isAxiosError) {
        switch (error.response?.status) {
          case UNAUTHORIZED_ERROR: {
            switch (error.response.data?.infoCode?.code) {
              case SESSION_EXPIRED: {
                Store.dispatch(refreshToken());
                break;
              }
              case INVALID_AUTH_TOKEN: {
                toast.dark('Invalid Auth Token. Please Login again.');
                Store.dispatch(logout());
                break;
              }
              case USER_INACTIVE: {
                toast.dark('Inactive user. Please login again');
                Store.dispatch(logout());
                break;
              }
              default: {
              }
            }
          }
        }
      }
      return Promise.reject(error);
    },
  );
};
export default Interceptor;
