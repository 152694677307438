import { createAsyncThunk, createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { Order } from 'api/types/order.types';
import { RootState } from 'redux/reducers/rootReducer';
import { TimeSlots } from 'api/types/timeSlot.types';
import { Cards } from 'api/types/cards.types';
import { Customer } from 'api/types/user.types';
import { Error } from 'api/types/error.types';
import {
  fetchDropoffTimeSlotsAPI,
  fetchPickupTimeSlotsAPI,
  fetchOrderDetailsAPI,
  updateActiveOrderAPI,
  addFailedOrderPaymentCardAPI,
  rateOrderAPI,
} from 'api/customerAPI';
import { createNewOrder } from 'pages/newOrder/newOrder.thunks';

export const fetchOrderDetails = createAsyncThunk(
  'orderDetails/fetchOrderDetails',
  async (orderId: string) => {
    return await fetchOrderDetailsAPI(orderId);
  },
);

export const updateCurrentOrder = createAsyncThunk(
  'orderDetails/updateCurrentOrder',
  async (data: Order.UpdateOrder, thunkApi) => {
    const {
      orderDetails: { currentOrder },
    } = thunkApi.getState() as RootState;

    if (!currentOrder?.id) {
      return;
    }

    const { order } = await updateActiveOrderAPI(currentOrder?.id, data);
    return { order };
  },
);

export const fetchPickupTimeSlots = createAsyncThunk(
  'orderDetails/fetchPickupTimeSlots',
  async (config, thunkApi) => {
    const {
      orderDetails: { currentOrder },
    } = thunkApi.getState() as RootState;

    const pickupTimeslotsConfig: TimeSlots.BaseProps = {
      addressId: currentOrder?.address.id || '',
      orderType: currentOrder?.type || 'FLEX',
    };

    const { pickupTimeslotsList } = await fetchPickupTimeSlotsAPI(pickupTimeslotsConfig);
    return { pickupTimeslotsList };
  },
);

export const rateOrder = createAsyncThunk(
  'orderDetails/rateOrder',
  async (config: { orderId: string; data: Order.Rating }) => {
    return await rateOrderAPI(config.orderId, config.data);
  },
);

export const fetchDropoffTimeSlots = createAsyncThunk(
  'orderDetails/fetchDropoffTimeSlots',
  async (config, thunkApi) => {
    const {
      orderDetails: { currentOrder },
    } = thunkApi.getState() as RootState;

    const dropoffTimeslotsConfig: TimeSlots.DropoffProps = {
      addressId: currentOrder?.address.id || '',
      orderType: currentOrder?.type!,
      pickupDate: currentOrder?.pickupDate || '',
      orderId: currentOrder?.id,
      isDropoffAtDoor: currentOrder?.isDropoffAtDoor
    };

    const { dropoffTimeslotsList } = await fetchDropoffTimeSlotsAPI(dropoffTimeslotsConfig);
    return { dropoffTimeslotsList };
  },
);

export const addOrderPaymentCard = createAsyncThunk<
  {
    order: Order.Order;
    user: Customer.Customer;
  },
  Cards.CardForm,
  { rejectValue: Error.WashmenServerError }
>('orderDetails/addOrderPaymentCard', async (payload: Cards.CardForm, thunkApi) => {
  const {
    orderDetails: { currentOrder = { id: '' } },
  } = thunkApi.getState() as RootState;
  const rejectWithValue = thunkApi.rejectWithValue;
  try {
    const response = await addFailedOrderPaymentCardAPI(currentOrder?.id, payload);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

interface setCurrentOrderPayloadAction {
  payload: Order.Order | undefined;
  type: string;
}

interface State {
  currentOrder?: Order.Order;
  timeSlots: PickupDropoffTimeSlots;
}

interface PickupDropoffTimeSlots {
  pickupTimeslotsData?: TimeSlots.TimeslotsData;
  dropoffTimeslotsData?: TimeSlots.TimeslotsData;
}

const initialState: State = {
  currentOrder: undefined,
  timeSlots: {},
};

const slice = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {
    setCurrentOrder(state, action: setCurrentOrderPayloadAction) {
      state.currentOrder = action?.payload;
    },
    resetTimeslots(state, action:PayloadAction<'PICKUP'|'DROPOFF'>){
      if(action.payload === 'DROPOFF'){
        state.timeSlots.dropoffTimeslotsData = undefined;
      }
      else if(action.payload === 'PICKUP'){
        state.timeSlots.pickupTimeslotsData = undefined;
      }
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPickupTimeSlots.fulfilled, (state, action) => {
        const { pickupTimeslotsList: pickupTimeslotsData } = action.payload;
        state.timeSlots.pickupTimeslotsData = pickupTimeslotsData;
      })
      .addCase(fetchDropoffTimeSlots.fulfilled, (state, action) => {
        const { dropoffTimeslotsList: dropoffTimeslotsData } = action.payload;
        state.timeSlots.dropoffTimeslotsData = dropoffTimeslotsData;
      })
      .addCase(fetchOrderDetails.fulfilled, (state, action) => {
        state.currentOrder = action.payload;
      })
      .addCase(updateCurrentOrder.fulfilled, (state, action) => {
        if (action.payload?.order) {
          state.currentOrder = action.payload.order;
        }
      })
      .addCase(addOrderPaymentCard.fulfilled, (state, action) => {
        if (action.payload?.order) {
          state.currentOrder = action.payload.order;
        }
      })
      .addCase(createNewOrder.fulfilled, (state, action) => {
        state.currentOrder = action.payload.newOrder;
      });
  },
});

export const selectTimeslots = (state: RootState) => state.orderDetails.timeSlots;

export const selectPickupTimeSlots = createSelector(selectTimeslots, (state) => {
  return state.pickupTimeslotsData;
});

export const selectDropoffTimeSlots = createSelector(selectTimeslots, (state) => {
  return state.dropoffTimeslotsData;
});

export const selectCurrentOrder = (state: RootState) => state.orderDetails.currentOrder;

export const { setCurrentOrder, resetTimeslots } = slice.actions;

export default slice.reducer;
