import i18n from 'i18n/config';
import React from 'react';
import ContentLoader from 'react-content-loader';

const OptionItemLoader: React.FC = (props) => {
  const isRTL = i18n.dir() === 'rtl';
  return (
    <ContentLoader
      speed={0.8}
      width="100%"
      height="38"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      rtl={isRTL}
      {...props}
    >
      <rect x="48" y="8" rx="3" ry="3" width="50%" height="20" />
      <circle cx="19" cy="19" r="19" />
    </ContentLoader>
  );
};

export default OptionItemLoader;
