import { LanguageCodes } from 'i18n';
import i18n from 'i18next';
import * as EN from './en';
import * as AR from './ar';

export const getDynamicIcons = () => {
  const language = i18n.language as LanguageCodes;
  switch (language) {
    case LanguageCodes.AR:
      return AR;
    case LanguageCodes.EN:
    default:
      return EN;
  }
};
