import { validateApplePaySessionAPI } from 'api/customerAPI';
import { toast } from 'react-toastify';
import { notifyErrorObject } from './reporting';

interface TWindow extends Window {
  ApplePaySession?: ApplePaySession
}
declare var window: TWindow;


const APPLE_PAY_WEB_VERSION = 3;

const DEFAULT_REQUEST: ApplePayJS.ApplePayPaymentRequest = {
  countryCode: 'AE',
  currencyCode: 'AED',
  supportedNetworks: ['visa', 'masterCard', 'amex'],
  merchantCapabilities: ['supports3DS'],
  total: { label: 'Washmen', type: 'pending', amount: '1' },
};

/**
 * @description checks Apple Pay Compatibility
 */
export const checkApplyPayCompatibility = () => {
  // if (isPartner()) {
  //   return false;
  // }
  if (window.ApplePaySession && ApplePaySession.STATUS_FAILURE) {
    try {
      return ApplePaySession.canMakePayments();
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
};

export const performApplePayPayment = (
  paymentRequest: ApplePayJS.ApplePayPaymentRequest = DEFAULT_REQUEST,
) => {
  return new Promise<any>((resolve, reject) => {
    var session = new ApplePaySession(APPLE_PAY_WEB_VERSION, paymentRequest);
    session.onvalidatemerchant = (event) => {
      try {
        validateApplePaySessionAPI(event.validationURL)
          .then((merchantSession) => {
            session.completeMerchantValidation(merchantSession);
          })
          .catch((e) => {
            toast.dark('Failed to verify Apple Pay, please try a different payment method');
            notifyErrorObject(e);
            reject();
          });
      } catch (e) {
        reject();
      }
    };
    session.onpaymentauthorized = (event) => {
      session.completePayment({
        status: 0,
      });
      resolve(event.payment.token.paymentData);
    };
    session.oncancel = reject;
    session.begin();
  });
};
