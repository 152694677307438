interface UserData {
  id: string;
  name: string;
  email: string;
}

export function identifyUser(user: UserData) {
  try {
    const { id, name, email } = user;
    //@ts-ignore
    window.hj('identify', id, {
      name,
      email,
    });
  } catch (e) {
    console.log('Hotjar identifyUser() error:', e);
  }
}
