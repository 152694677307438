import { Config } from 'config/types';
import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPartner } from 'redux/slices/authSlice';

const ConfigContext = createContext<Config | undefined>({});

export const ConfigProvider: React.FC = ({ children }) => {
  const partnerId = useSelector(selectPartner);
  const [config, setConfig] = useState<Config>();

  useEffect(() => {
    if (!partnerId) return;
    const fetchConfigFile = async () => {
      try {
        const file = (await import(`config/${partnerId.toLowerCase()}.config`)).default;
        setConfig(file);
      } catch (e) {
        setConfig({});
      }
    };
    fetchConfigFile();
  }, [partnerId]);

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => {
  const config = React.useContext(ConfigContext);
  return config;
};

export default ConfigProvider;
