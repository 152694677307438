import i18n from 'i18n/config';
import React from 'react';
import ContentLoader from 'react-content-loader';
const ServiceLoader: React.FC = (props) => {
  const isRTL = i18n.dir() === 'rtl';
 return (
    <ContentLoader
    speed={0.8}
    width="100%"
    height={180}
    // viewBox="0 0 476 180"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    rtl={isRTL}
    {...props}
  >
    <rect x="0" y="0" rx="12" ry="12" width="116" height="161" />
    <rect x="130" y="0" rx="12" ry="12" width="116" height="161" />
    <rect x="260" y="0" rx="12" ry="12" width="116" height="161" />
    <rect x="390" y="0" rx="12" ry="12" width="116" height="161" />
  </ContentLoader>
);
 }
export default ServiceLoader;
