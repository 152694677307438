import { Theme } from './types';

const defaultTheme: Theme = {
  colors: {
    primary: '#3e48a6',
    lightPrimary: '#e8eaff',
    secondary: '#00b8ab',
    secondaryDark: '#00b8ab',
    success: '#00b8ab',
    danger: '#c83c3e',
    warning: '#ffc042',
    light: '#fcfcfc',
    dark: '#111135',
    subtitleDark: '#585871',
    separatorGray: '#f2f3f8',
    gray600: '#b6bcd1',
  },

  button: {
    border: {
      width: 0,
      radius: 8,
      color: 'transparent',
    },
    hidePlaceOrderArrow: false,
  },

  text: {
    fontFamily: 'Ubuntu, sans-serif',
    webFontLoader: 'Ubuntu:300,400,500&display=swap',
    color: '#3E48A6',
    subtitleText: '#585871',
    slotBoxTextColor: '#FFFF',
    tipBoxTextColorUnselected: '#141929',
    tipBoxTextColorSelected: '#FFF',
    orderStatusTextColor: '#291482',
  },

  footer: {
    background: '#E8EAFF',
    boxShadow:
      '0 1px 0 0 rgba(182, 188, 209, 0.2), 0 1px 4px 0 rgba(182, 188, 209, 0.2), 0 -1px 4px 0 rgba(182, 188, 209, 0.2)',
  },

  card: {
    unselectedColor: '#f2f4f7',
    successColor: '#ebfbf9',
    border: {
      width: 0,
      radius: 8,
    },
    text: {
      bulletColorSelected: '#3e48a6',
      bulletColorUnselected: '#b6bcd1',
      headerColorLeft: '#3e48a6',
      headerColorRight: '#111135',
      bodyColor: '#111135',
    },
  },

  icon: {
    color: '#414C9A',
    disabledColor: '#b6bcd1',
    hasFixedStyle: false,
    stepperIconSize: 28,
  },

  stepper: {
    borderColor: '#414B9F',
  },

  bottomSheet: {
    borderRadius: 0,
    mostPopularTextColor: '#ffc042',
  },
};

export default defaultTheme;
