import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { getPricingAPI } from 'api/customerAPI';
import { RootState } from 'redux/reducers/rootReducer';
import { PricingList } from 'api/types/pricing.types';

interface State {
  pricingList?: PricingList.Pricing;
}

const initialState: State = {};

// Thunks
export const fetchPricing = createAsyncThunk('pricing/fetchPricing', async () => {
  return (await getPricingAPI()).pricing;
});

const slice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPricing.fulfilled, (state, action) => {
      state.pricingList = action.payload;
    });
  },
});

export const selectPricing = (state: RootState) => state.pricing.pricingList;

export const selectServices = createSelector(selectPricing, (state) => {
  if (!state) return [];
  const servicesMap = new Map<PricingList.ServiceTypes, PricingList.CombinedService | undefined>();
  // sort services custom
  servicesMap.set('SHOE_CLEANING', state.services.SHOE_CLEANING);
  servicesMap.set('CLEAN_PRESS', state.services.WASH_FOLD);
  servicesMap.set('PRESS', state.services.PRESS);
  servicesMap.set('HOME_CARE', state.services.HOME_CARE);
  servicesMap.set('WASH_FOLD', state.services.CLEAN_PRESS);
  return Array.from(servicesMap, ([type, service]) => [type, service]) as [
    PricingList.ServiceTypes,
    PricingList.CombinedService,
  ][];
});

export const selectCurrency = createSelector(selectPricing, (state) => state?.currency);

export const selectPricingList = createSelector(
  selectPricing,
  (_: RootState, service: PricingList.ServiceTypes) => service,
  (pricingList, service) => pricingList?.services[service],
);

export default slice.reducer;
