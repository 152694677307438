import { combineReducers } from '@reduxjs/toolkit';

// reducers
import uiReducer from '../slices/uiSlice';
import authReducer from '../slices/authSlice';
import newOrderReducer from 'pages/newOrder/newOrder.slice';
import pricingReducer from 'redux/slices/pricingSlice';
import activeOrdersReducer from 'pages/orders/modules/activeOrders/activeOrders.slice';
import pastOrdersReducer from 'pages/orders/modules/pastOrders/pastOrders.slice';
import orderDetailsReducer from 'pages/orderDetails/orderDetails.slice';

const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  newOrder: newOrderReducer,
  pricing: pricingReducer,
  activeOrders: activeOrdersReducer,
  pastOrders: pastOrdersReducer,
  orderDetails: orderDetailsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
