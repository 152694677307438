import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { languageCodes, resources } from './constants';
import { getBrowserLanguage } from 'utils/browserLanguage';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getBrowserLanguage(),
    fallbackLng: languageCodes,
    preload: [getBrowserLanguage()],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
