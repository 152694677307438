import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/reducers/rootReducer';
import { getClientMediaAPI } from 'api/integrationAPI';

import { LogoIconFile } from 'assets/icons/';
import { getBrowserLanguage } from 'utils/browserLanguage';
import { LanguageCodes } from 'i18n';
import { updateUserLanguage } from './authSlice';
import { fetchCarouselData } from 'api/customerAPI';
import { CarouselItem } from 'components/carousel';

export const updateAppLanguage = createAction(
  'core/updateAppLanguage',
  (language?: LanguageCodes) => {
    return {
      payload: {
        language,
      },
    };
  },
);

export const getClientMedia = createAsyncThunk('core/getClientMedia', async (payload: string) => {
  return await getClientMediaAPI(payload);
});

export const listCarouselData = createAsyncThunk('user/fetchCarouselData', async () => {
  const carouselItemList = await fetchCarouselData();
  return carouselItemList;
});

interface UIState {
  activeStep: number;
  sheetsCounter: number;
  logoImage: string;
  carouselData: CarouselItem[];
  loading: boolean;
  appLanguage: LanguageCodes;
  targetTheme: string;
}

const initialState: UIState = {
  activeStep: 0,
  sheetsCounter: 0,
  logoImage: '',
  carouselData: [],
  loading: false,
  appLanguage: (localStorage.getItem('language') as LanguageCodes) || getBrowserLanguage(),
  targetTheme: '',
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setActiveStep(state, action: PayloadAction<number>) {
      state.activeStep = action.payload;
    },
    sheetOpened(state) {
      state.sheetsCounter++;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    sheetClosed(state) {
      if (state.sheetsCounter === 0) return;
      state.sheetsCounter--;
    },
    setDefaultMedia(state) {
      state.logoImage = LogoIconFile;
    },
    setAppLanguage(state, action: PayloadAction<LanguageCodes>) {
      state.appLanguage = action.payload;
    },
    setTargetTheme(state, action: PayloadAction<string>) {
      state.targetTheme = action.payload;
    },
  },
  extraReducers: (builder) => {
    function sharedLoadingOffReducer(state: typeof initialState) {
      state.loading = false;
    }

    builder
      .addCase(getClientMedia.fulfilled, (state, action) => {
        const { logo = LogoIconFile } = action.payload.clientMedia;
        state.logoImage = logo;
      })
      .addCase(getClientMedia.rejected, (state, action) => {
        state.logoImage = LogoIconFile;
      })
      .addCase(updateAppLanguage, (state, action) => {
        if (action.payload.language) {
          state.appLanguage = action.payload.language;
        }
      })
      .addCase(updateUserLanguage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserLanguage.fulfilled, sharedLoadingOffReducer)
      .addCase(updateUserLanguage.rejected, sharedLoadingOffReducer)
      .addCase(listCarouselData.fulfilled, (state, action) => {
        state.carouselData = action.payload;
      });
  },
});

export const {
  setActiveStep,
  sheetClosed,
  setLoading,
  sheetOpened,
  setDefaultMedia,
  setAppLanguage,
  setTargetTheme,
} = slice.actions;

export const selectActiveStep = (state: RootState) => state.ui.activeStep;

export const selectLoading = (state: RootState) => state.ui.loading;

export const selectSheetsCounter = (state: RootState) => state.ui.sheetsCounter;

export const selectLogoImage = (state: RootState) => state.ui.logoImage;
export const selectTargetTheme = (state: RootState) => state.ui.targetTheme;
export const selectCarouselData = (state: RootState) => state.ui.carouselData;
export const selectAppLanguage = (state: RootState) =>
  state.auth.customer?.language || state.ui.appLanguage;

export default slice.reducer;
