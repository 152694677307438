import { PricingList } from 'api/types/pricing.types';

type API = 'authenticate' | 'addAddress' | 'initiatePayment' | 'fixPayment';

export interface Config {
  excludeBags?: PricingList.ServiceTypes[];
  pricingSubtitle?: string;
  isAuthDisabled?: boolean;
  hideHeader?: boolean;
  showHomeDownloadAppBanner?: boolean;
  headerMarginRight?: number;
  deniesInlineMediaPlayback?: boolean;
  api?: Partial<Record<API, Function>>;
  disablePayment?: boolean;
  disablePromocode?: boolean;
  hideBackButton?: boolean;
  paymentIconColored?: JSX.Element;
  paymentIconWhite?: JSX.Element;
  paymentButtonText?: string;
  donotShowAddPaymentSheetOnFixPayment?: boolean;
  plusIcon?: JSX.Element;
}

// TODO: add more integration partners
export enum INTEGRATION_PARTNERS {
  CAREEM = 'CAREEM',
  RIZEK = 'RIZEK',
  KAIZEN = 'KAIZEN',
  SONDER = 'SONDER',
  APP = 'APP',
}
