import { Theme } from 'assets/themes/types';
import { useEffect } from 'react';
import { useTheme } from 'styled-components';
import useMessaging from './useMessaging';

const useIntercomTheme = () => {
  const theme: Theme = useTheme();
  const { update } = useMessaging();

  useEffect(() => {
    update({
      background_color: theme.intercom?.backgroundColor,
      action_color: theme.intercom?.actionColor,
    });
  }, [update, theme]);
};

export default useIntercomTheme;
