import { WASHMEN_CUSTOMER_API } from './config';
import { PricingList } from 'api/types/pricing.types';
import { Customer } from 'api/types/user.types';
import { TimeSlots } from './types/timeSlot.types';
import { Preferences } from './types/preferences.types';
import { Cards } from './types/cards.types';
import { Order } from './types/order.types';
import { AxiosRequestConfig } from 'axios';
import { Addresses } from './types/addresses.types';
import { AddressForm } from 'schemas/address-schema';
import { LanguageCodes } from 'i18n';

export async function fetchCarouselData() {
  const {
    data: { carouselItemList },
  } = await WASHMEN_CUSTOMER_API.get<Customer.CarouselData>('/stories/carousel-items');
  return carouselItemList;
}

export async function getPricingAPI() {
  const { data } = await WASHMEN_CUSTOMER_API.get<PricingList.RootObject>('/pricings', {
    params: { isFetchShoeCare: true },
  });
  return data;
}

export async function fetchCustomerAPI() {
  const { data } = await WASHMEN_CUSTOMER_API.get<Customer.UserRootResponse>('/users');
  return data;
}

export async function fetchPickupTimeSlotsAPI(config: TimeSlots.BaseProps) {
  let params: TimeSlots.BaseProps = {
    orderType: config.orderType,
    addressId: config.addressId,
  };

  return (
    await WASHMEN_CUSTOMER_API.get<TimeSlots.PickupRootObject>(`/timeslots/pickup`, {
      params,
    })
  ).data;
}

export async function fetchDropoffTimeSlotsAPI(config: TimeSlots.DropoffProps) {
  let params: TimeSlots.DropoffProps = {
    orderType: config.orderType,
    addressId: config.addressId,
    pickupDate: config.pickupDate,
    orderId: config.orderId,
    isDropoffAtDoor: config.isDropoffAtDoor
  };

  return (
    await WASHMEN_CUSTOMER_API.get<TimeSlots.DropoffRootObject>(`/v2/timeslots/dropoff`, {
      params,
    })
  ).data;
}

export async function fetchCustomerPreferencesAPI() {
  return (await WASHMEN_CUSTOMER_API.get<Preferences.RootObject>('/orders/preferences')).data;
}

export async function fetchCustomerPaymentCardsAPI() {
  return (await WASHMEN_CUSTOMER_API.get<Cards.RootObject>('/cards')).data;
}

export async function addPaymentCardAPI(card: Cards.CardForm) {
  return await WASHMEN_CUSTOMER_API.post('/cards', card);
}

export async function updateDefaultPaymentAPI(
  paymentMethod: Cards.PaymentMethods,
  cardId?: string,
) {
  return await WASHMEN_CUSTOMER_API.put('/customers/payment', { paymentMethod, cardId });
}

export async function updateLanguageAPI(data: { language: LanguageCodes }) {
  return (await WASHMEN_CUSTOMER_API.put<{ user: Customer.Customer }>('/customers/language', data))
    .data;
}

export async function validateApplePaySessionAPI(url: string) {
  return (await WASHMEN_CUSTOMER_API.post('/apple-pay/validate-session', { url })).data
    .applePaySessionObject;
}

export async function addFailedOrderPaymentCardAPI(orderId: string, card: Cards.CardForm) {
  return (
    await WASHMEN_CUSTOMER_API.put<{ order: Order.Order; user: Customer.Customer }>(
      `/orders/${orderId}/card`,
      card,
    )
  ).data;
}

export async function deletePaymentCardAPI(cardId: string) {
  return await WASHMEN_CUSTOMER_API.delete(`/cards/${cardId}`);
}

export async function addPromoCodeAPI(promocode: string) {
  return await WASHMEN_CUSTOMER_API.post(`/customers/promotions/apply`, { promocode });
}

export async function createNewOrderAPI(newOrder: Order.NewOrder) {
  if (newOrder.preferences && newOrder.preferences.folding === '') {
    //@ts-ignore
    newOrder.preferences.folding = undefined;
  }
  if (newOrder.preferences && newOrder.preferences.creases === '') {
    //@ts-ignore
    newOrder.preferences.creases = undefined;
  }
  return (
    await WASHMEN_CUSTOMER_API.post<{
      customerPreferences: Preferences.CustomerPreferences;
      newOrder: Order.Order;
      updatedUser: Customer.Customer;
    }>('/orders', newOrder, { params: { isAutoSelectNextAvailablePickupSlot: true } })
  ).data;
}

export async function fetchOrderDetailsAPI(orderId: string) {
  return (await WASHMEN_CUSTOMER_API.get<{ order: Order.Order }>(`/orders/${orderId}`)).data.order;
}

export async function fetchActiveOrdersListAPI() {
  return (await WASHMEN_CUSTOMER_API.get<{ orders: Order.Order[] }>('/active-orders?sort=DESC'))
    .data.orders;
}

export async function fetchPastOrdersListAPI(pageIndex: number = 1, pageSize: number = 10) {
  return (
    await WASHMEN_CUSTOMER_API.get<{ orders: Order.Order[]; meta: Order.PaginationMeta }>(
      `/past-orders?pageSize=${pageSize}&pageIndex=${pageIndex}&sort=DESC`,
    )
  ).data;
}

export async function updateActiveOrderAPI(orderId: string, data = {}) {
  return (
    await WASHMEN_CUSTOMER_API.put<{ order: Order.Order }>(`/orders/${orderId}`, data, {
      params: { isAutoSelectNextAvailablePickupSlot: true },
    })
  ).data;
}

export async function cancelActiveOrderAPI(orderId: string, reason = 'No reason') {
  return (
    await WASHMEN_CUSTOMER_API.delete<{ updatedUser: Customer.Customer }>(`/orders/${orderId}`, {
      data: {
        reason,
      },
    })
  ).data;
}

export async function rateOrderAPI(orderId: string, data = {}) {
  return await WASHMEN_CUSTOMER_API.put(`/orders/${orderId}/rate`, data);
}

export async function sendVerificationCodeAPI(phone: string) {
  return (await WASHMEN_CUSTOMER_API.post('/auth/send-verification-code', { phone })).data;
}

export async function verifyCodeAPI(tempCode: string, phone: string) {
  interface Response {
    deformedEmail?: string;
    infoCode?: {
      message: string;
      code: number;
    };
  }
  return await WASHMEN_CUSTOMER_API.post<Response>('/auth/verify-temp-code', { phone, tempCode });
}

export async function editPhoneNumberAPI(oldPhone: string, phone: string, tempCode: string) {
  return (
    await WASHMEN_CUSTOMER_API.put<{ user: Customer.Customer }>('/users/phone', {
      oldPhone,
      tempCode,
      phone,
    })
  ).data.user;
}

export async function loginAPI(
  tempCode: string,
  phone: string,
  email: string,
  thirdPartyId?: string,
  authTrackId?: string,
  customOriginApp?: string,
  language?: LanguageCodes,
) {
  const { data } = await WASHMEN_CUSTOMER_API.post<Customer.LoginRootResponse>('/auth/login', {
    phone,
    tempCode,
    email,
    thirdPartyId,
    authTrackId,
    customOriginApp,
    language,
  });
  return data;
}

export async function logoutAPI(auth: string, refresh: string) {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: '/auth/logout',
    headers: {
      auth,
      refresh,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  return (await WASHMEN_CUSTOMER_API.request(config)).data;
}

export async function refreshTokenAPI() {
  return (await WASHMEN_CUSTOMER_API.post<Customer.LoginRootResponse>('/auth/refresh')).data;
}

export interface RegisterUserInputs {
  tempCode: string;
  phone: string;
  email: string;
  overwrite?: boolean;
  firstName: string;
  lastName: string;
  language: LanguageCodes;
  thirdPartyId?: string;
  authTrackId?: string;
  customOriginApp?: string;
}
export async function registerUserAPI(data: RegisterUserInputs) {
  return (await WASHMEN_CUSTOMER_API.post<Customer.LoginRootResponse>('/users', data)).data;
}

export interface UpdateUserInputs {
  email?: string;
  firstName?: string;
  lastName?: string;
}
export interface UpdateLanguageInputs {
  language: string;
}
export async function updateUserAPI(data: UpdateUserInputs) {
  return (await WASHMEN_CUSTOMER_API.put<{ user: Customer.Customer }>('/users', data)).data;
}

export async function verifyAuthTokenAPI(token: string) {
  return (
    await WASHMEN_CUSTOMER_API.post<Customer.LoginRootResponse>('/auth/third-party/verify', {
      token,
    })
  ).data;
}

export async function fetchCustomerAddressesAPI() {
  return (await WASHMEN_CUSTOMER_API.get<{ addresses: Addresses.Address[] }>('/addresses')).data;
}

export async function addCustomerAddressAPI(address: AddressForm) {
  return (await WASHMEN_CUSTOMER_API.post<{ address: Addresses.Address }>('/addresses', address))
    .data;
}

export async function deleteCustomerAddressAPI(id: string) {
  return (await WASHMEN_CUSTOMER_API.delete<{ updatedUser: Customer.Customer }>(`/addresses/${id}`))
    .data;
}

export async function editCustomerAddressAPI(address: AddressForm, addressId: string) {
  return (
    await WASHMEN_CUSTOMER_API.put<{ address: Addresses.Address }>(
      `/addresses/${addressId}`,
      address,
    )
  ).data.address;
}

export async function validateAddressAPI(position: { lat: number; lng: number }) {
  return (
    await WASHMEN_CUSTOMER_API.get<{ geofence: Addresses.Geofence }>(`/addresses/check`, {
      params: {
        country: 'uae',
        lat: position.lat,
        lng: position.lng,
      },
    })
  ).data;
}
