import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { Cards } from 'api/types/cards.types';
import { Preferences } from 'api/types/preferences.types';
import { TimeSlots } from 'api/types/timeSlot.types';
import {
  fetchCustomerPaymentCards,
  fetchDropoffTimeSlots,
  fetchOrderPreferences,
  fetchPickupTimeSlots,
  getCustomerAddresses,
  addCustomerAdddress,
  deleteCustomerAddress,
  editCustomerAddress,
  confirmApplePayMethod,
} from './newOrder.thunks';
import { dateFormat } from 'utils/dateFormat';
import { Addresses } from 'api/types/addresses.types';
import { checkApplyPayCompatibility } from 'wrappers/apple_pay';

interface NewOrder {
  addressId?: string;
  orderType?: 'FLEX' | 'PRIORITY';
  pickupTimeslot?: TimeSlots.Timeslot;
  isPickupAtDoor?: boolean;
  dropoffTimeslot?: TimeSlots.Timeslot;
  isDropoffAtDoor?: boolean;
  creditCardId?: string;
  applePayPayload?: any;
  preferences?: Preferences.CustomerPreferences;
  driverTip?: number;
}

interface PickupDropoffTimeSlots {
  pickupTimeslotsData?: TimeSlots.TimeslotsData;
  dropoffTimeslotsData?: TimeSlots.TimeslotsData;
}

export const addressesAdapter = createEntityAdapter<Addresses.Address>({
  selectId: (address) => address.id,
  sortComparer: (a, b) => (a.isInUse === b.isInUse ? 0 : a.isInUse ? -1 : 1),
});

const addressesReducer = (
  state: State,
  action: PayloadAction<{
    addresses: Addresses.Address[];
    isFirstOrder: boolean;
  }>,
) => {
  state.statuses.addresses = 'idle';

  const { addresses, isFirstOrder } = action.payload;
  const currentUsedAddressId = state.currentOrder.addressId;

  // set current address Id as last used address
  if (!isFirstOrder && !currentUsedAddressId) {
    const lastUsedAddress = addresses.find((i) => i.isLastUsedAddress);
    if (lastUsedAddress) {
      state.currentOrder.addressId = lastUsedAddress.id;
    } else if (addresses.length > 0) {
      // fallback in case last used address was not found in addresses
      // state.currentOrder.addressId = addresses[0].id;
    }
  }

  if (!isFirstOrder && state.currentOrder.addressId) {
    state.currentOrder.orderType = 'FLEX';
  }

  // set addresses in store
  addressesAdapter.setAll(
    state.addresses,
    addresses.map((address) => ({
      ...address,
      isInUse: state.currentOrder.addressId === address.id,
    })),
  );
};

interface State {
  currentOrder: NewOrder;
  timeSlots: PickupDropoffTimeSlots;
  cards?: Cards.Card[];
  addresses: EntityState<any>;
  statuses: {
    addresses: 'idle' | 'pending';
    pickupTimeslots: 'idle' | 'pending';
    dropoffTimeslots: 'idle' | 'pending';
  };
}

const initialState: State = {
  currentOrder: {
    pickupTimeslot: undefined,
    dropoffTimeslot: undefined,
    isDropoffAtDoor: true,
    driverTip: 0,
  },
  timeSlots: {},
  addresses: addressesAdapter.getInitialState(),
  statuses: {
    addresses: 'idle',
    pickupTimeslots: 'idle',
    dropoffTimeslots: 'idle',
  },
};

const slice = createSlice({
  name: 'newOrder',
  initialState,
  reducers: {
    updateNewOrder(state, action: PayloadAction<NewOrder>) {
      const order = action.payload;
      state.currentOrder = {
        ...state.currentOrder,
        ...order,
      };
    },
    updateOrderType(state, action: PayloadAction<'FLEX' | 'PRIORITY'>) {
      const orderType = action.payload;
      if (orderType !== state.currentOrder.orderType) {
        state.currentOrder.pickupTimeslot = undefined;
        state.currentOrder.dropoffTimeslot = undefined;
        state.currentOrder.isDropoffAtDoor = true;
        state.timeSlots = {};
      }
      state.currentOrder.orderType = orderType;
    },
    updateIsDropoffAtDoor(state, action:PayloadAction<boolean>){
      const isDropoffAtDoor = action.payload;
      state.currentOrder.dropoffTimeslot = undefined;
      state.timeSlots.dropoffTimeslotsData = undefined;
      state.currentOrder.isDropoffAtDoor = isDropoffAtDoor;
    },
    updatePreferences(state, action: PayloadAction<Preferences.CustomerPreferences>) {
      state.currentOrder.preferences = action.payload;
    },
    setDriverTip(state, action: PayloadAction<number>) {
      state.currentOrder.driverTip = action.payload;
    },
    updateOrderApplePayPayload(state, action: PayloadAction<object>) {
      state.currentOrder.applePayPayload = action.payload;
    },
    setSelectedPaymentCard(state, action: PayloadAction<string>) {
      state.currentOrder.creditCardId = action.payload;
    },
    selectAddress(state, action: PayloadAction<string>) {
      const addressId = action.payload;
      state.currentOrder.addressId = addressId;
      addressesAdapter.updateMany(
        state.addresses,
        state.addresses.ids.map((id) => ({
          id,
          changes: {
            isInUse: id === addressId ? true : false,
          },
        })),
      );
    },
    addressAdded(state, action: PayloadAction<{ address: Addresses.Address }>) {
      addressesAdapter.addOne(state.addresses, action.payload.address);
      addressesAdapter.updateMany(
        state.addresses,
        state.addresses.ids.map((id) => ({
          id,
          changes: {
            isInUse: id === action.payload.address.id,
          },
        })),
      );
      state.currentOrder.addressId = action.payload.address.id;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPickupTimeSlots.pending, (state, action) => {
        state.statuses.pickupTimeslots = 'pending';
        state.statuses.dropoffTimeslots = 'pending';
      })
      .addCase(fetchPickupTimeSlots.fulfilled, (state, action) => {
        state.statuses.pickupTimeslots = 'idle';

        const { pickupTimeslotsList: pickupTimeslotsData, isFirstOrder } = action.payload;

        state.timeSlots.pickupTimeslotsData = pickupTimeslotsData;
        state.currentOrder.pickupTimeslot = undefined;
        state.currentOrder.isPickupAtDoor = pickupTimeslotsData.collectionMethod.isAtDoorByDefault;

        // For non-first time customers, set inital pickup timeslots
        if (!isFirstOrder) {
          const todayDate = dateFormat(String(new Date()), 'isoDate');
          const date =
            pickupTimeslotsData.presetDate || pickupTimeslotsData.predefinedDate || todayDate;
          const slot = pickupTimeslotsData.presetSlot || pickupTimeslotsData.predefinedSlot;
          if (slot) {
            state.currentOrder.pickupTimeslot = {
              date,
              ...slot,
            };
          }
        }
      })
      .addCase(fetchPickupTimeSlots.rejected, (state, action) => {
        state.statuses.pickupTimeslots = 'idle';
        state.statuses.dropoffTimeslots = 'idle';
      })
      .addCase(fetchDropoffTimeSlots.pending, (state, action) => {
        state.statuses.dropoffTimeslots = 'pending';
      })
      .addCase(fetchDropoffTimeSlots.fulfilled, (state, action) => {
        state.statuses.dropoffTimeslots = 'idle';

        const { dropoffTimeslotsList: dropoffTimeslotsData, isFirstOrder } = action.payload;

        state.timeSlots.dropoffTimeslotsData = dropoffTimeslotsData;

        const selectedDropoffTimeslotDate = state.currentOrder.dropoffTimeslot?.date || '';

        // Keep selectedDropoffTimesplot as is, if still in range and non urgent, else reset selectedDropoffTimesplot
        if (selectedDropoffTimeslotDate && dropoffTimeslotsData.dates) {
          const previouslySelectedDay = dropoffTimeslotsData.dates.find(
            (d) => selectedDropoffTimeslotDate && d.date === selectedDropoffTimeslotDate,
          );
          const isPreviouslySelectedDayStillInRange = !!previouslySelectedDay;
          const isPreviouslySelectedDayNowUrgent =
            isPreviouslySelectedDayStillInRange && previouslySelectedDay?.isUrgentDay;
          if (!isPreviouslySelectedDayStillInRange || isPreviouslySelectedDayNowUrgent) {
            state.currentOrder.dropoffTimeslot = undefined;
          }
        }

        // For non-first time customers, set inital dropoff timeslots
        if (!isFirstOrder && !selectedDropoffTimeslotDate) {
          const todayDate = dateFormat(String(new Date()), 'isoDate');
          const date =
            dropoffTimeslotsData.presetDate || dropoffTimeslotsData.predefinedDate || todayDate;
          const slot = dropoffTimeslotsData.presetSlot || dropoffTimeslotsData.predefinedSlot;
          if (slot) {
            state.currentOrder.dropoffTimeslot = {
              date,
              ...slot,
            };
          }
          return;
        }

        // For first time customers, reset selected dropoff timeslot
        if (isFirstOrder) {
          state.currentOrder.dropoffTimeslot = undefined;
          return;
        }
      })
      .addCase(fetchDropoffTimeSlots.rejected, (state, action) => {
        state.statuses.dropoffTimeslots = 'idle';
      })
      .addCase(fetchOrderPreferences.fulfilled, (state, action) => {
        state.currentOrder.preferences = action.payload;
      })
      .addCase(fetchCustomerPaymentCards.fulfilled, (state, action) => {
        const { cards, lastSelectedPaymentMethod, lastSelectedCardId } = action.payload;
        state.cards = cards;
        if (lastSelectedPaymentMethod !== 'APPLE_PAY' || checkApplyPayCompatibility() === false) {
          // validate that existing cards contain last selected card
          const currentCard = cards.find((card) => card.id === lastSelectedCardId);
          if (currentCard) {
            state.currentOrder.creditCardId = lastSelectedCardId;
          } else {
            state.currentOrder.creditCardId = cards[0]?.id;
          }
        }
      })
      .addCase(getCustomerAddresses.pending, (state, action) => {
        state.statuses.addresses = 'pending';
      })
      .addCase(getCustomerAddresses.fulfilled, addressesReducer)
      .addCase(getCustomerAddresses.rejected, (state, action) => {
        state.statuses.addresses = 'idle';
      })
      .addCase(addCustomerAdddress.fulfilled, (state, action) => {
        addressesAdapter.addOne(state.addresses, action.payload.address);
        addressesAdapter.updateMany(
          state.addresses,
          state.addresses.ids.map((id) => ({
            id,
            changes: {
              isInUse: id === action.payload.address.id,
            },
          })),
        );
        state.currentOrder.addressId = action.payload.address.id;
      })
      .addCase(deleteCustomerAddress.fulfilled, (state, action) => {
        addressesAdapter.removeOne(state.addresses, action.payload.addressId);
      })
      .addCase(editCustomerAddress.fulfilled, (state, action) => {
        addressesAdapter.updateOne(state.addresses, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(confirmApplePayMethod, (state) => {
        // clear credit card id
        state.currentOrder.creditCardId = undefined;
      });
  },
});

export const {
  updateNewOrder,
  updateOrderType,
  updatePreferences,
  clearState,
  setDriverTip,
  setSelectedPaymentCard,
  updateOrderApplePayPayload,
  selectAddress,
  addressAdded,
  updateIsDropoffAtDoor
} = slice.actions;

export default slice.reducer;
