import { Middleware } from '@reduxjs/toolkit';
import { createNewOrder } from 'pages/newOrder/newOrder.thunks';
import { registerUser } from 'redux/slices/authSlice';
import { trackEvent } from 'wrappers/reporting';

export const reportingMiddleware: Middleware = (store) => (next) => (action) => {
  switch (action.type) {
    case registerUser.fulfilled.type: {
      trackEvent('RegistrationComplete');
      next(action);
      break;
    }
    case createNewOrder.pending.type: {
      trackEvent('OrderSubmitted', action.meta.arg);
      fbq('track', 'Purchase', { value: 0.0, currency: 'AED' });
      next(action);
      break;
    }
    case createNewOrder.fulfilled.type: {
      trackEvent('OrderPlaced', action.meta.arg);
      next(action);
      break;
    }
    case createNewOrder.rejected.type: {
      trackEvent('OrderNotPlaced', action.meta.arg);
      next(action);
      break;
    }
    default:
      next(action);
  }
};
