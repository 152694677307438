import { Middleware } from '@reduxjs/toolkit';
import { WASHMEN_CUSTOMER_API } from 'api/config';
import { Customer } from 'api/types/user.types';
import { identifyUser } from 'lib/hotjar';
import {
  login,
  refreshToken,
  registerUser,
  logout,
  updateUser,
  verifyToken,
  fetchCustomer,
  updateUserLanguage,
  setPartnerId,
  setAuth,
} from 'redux/slices/authSlice';
import MessagingService from 'wrappers/messaging';
import { clearUserProfile, setUserProfile } from 'wrappers/reporting';

export const authMiddleware: Middleware = (store) => (next) => (action) => {
  switch (action.type) {
    case verifyToken.fulfilled.type:
    case registerUser.fulfilled.type:
    case refreshToken.fulfilled.type:
    case setAuth.type:
    case login.fulfilled.type: {
      const { authToken, refreshToken, user } = action.payload as Customer.LoginRootResponse;
      // update API headers
      WASHMEN_CUSTOMER_API.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;

      // update local storage and store auth data
      localStorage.setItem('token', authToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', JSON.stringify(user));

      const userData = {
        id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      };

      //Error Reporting
      setUserProfile(userData);
      identifyUser(userData);

      // update messaging service details
      MessagingService.getInstance().update({
        email: user.email,
        user_id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        phone: user.phone,
      });

      next(action);
      break;
    }
    case refreshToken.rejected.type:
    case logout.fulfilled.type: {
      delete WASHMEN_CUSTOMER_API.defaults.headers.common['Authorization'];
      // clear local storage
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');

      // destroy intercom session
      MessagingService.getInstance().destroy();

      //clear reporting
      clearUserProfile();

      //reboot Intercom
      MessagingService.getInstance().initialize();

      next(action);
      break;
    }
    case fetchCustomer.fulfilled.type: {
      const user = action.payload as Customer.Customer;
      localStorage.setItem('user', JSON.stringify(user));
      next(action);
      break;
    }
    case updateUserLanguage.fulfilled.type: {
      const user = action.payload.user as Customer.Customer;
      localStorage.setItem('user', JSON.stringify(user));

      next(action);
      break;
    }

    case updateUser.fulfilled.type: {
      const user = action.payload as Customer.Customer;
      // update local storage
      localStorage.setItem('user', JSON.stringify(user));

      // update messaging service details
      MessagingService.getInstance().update({
        email: user.email,
        user_id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        phone: user.phone,
      });

      //update error reporting
      setUserProfile({
        id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      });

      next(action);
      break;
    }

    case setPartnerId.toString(): {
      sessionStorage.setItem('partner_id', action.payload);
      next(action);
      break;
    }
    default:
      next(action);
  }
};

export default authMiddleware;
