import { useEffect, useState } from 'react';

import PickupAtDoorPortraitActive from 'assets/images/pickup-at-door-portrait.jpg';
import DropoffAtDoorPortraitActive from 'assets/images/dropoff-at-door-portrait.jpg';
import MeetDriverPortraitActive from 'assets/images/meet-driver-portrait.jpg';

import PickupAtDoorLandscape from 'assets/images/pickup-at-door-landscape.jpg';
import MeetDriverLandscape from 'assets/images/meet-driver-landscape.jpg';
import DropoffAtDoorLandscape from 'assets/images/dropoff-at-door-landscape.jpg';

const imgs = [
  PickupAtDoorPortraitActive,
  DropoffAtDoorPortraitActive,
  MeetDriverPortraitActive,
  PickupAtDoorLandscape,
  MeetDriverLandscape,
  DropoffAtDoorLandscape,
];

function preloadImage(src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

export default function useImagePreloader(imageList: string[] = imgs) {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return;
      }

      const imagesPromiseList: Promise<any>[] = [];
      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i));
      }

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  return { imagesPreloaded };
}
