import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { fetchPastOrdersListAPI } from 'api/customerAPI';
import { Order } from 'api/types/order.types';
import { RootState } from 'redux/reducers/rootReducer';

const pastOrdersAdapter = createEntityAdapter<Order.Order>({
  selectId: (order) => order.id,
});
interface State {
  paginationMeta: Order.PaginationMeta;
  status: string;
  orders: EntityState<any>;
}

const initialState: State = {
  paginationMeta: {
    currentPage: 1,
    totalCount: 0,
    totalPages: 0,
    pageSize: 10
  },
  status: 'idle',
  orders: pastOrdersAdapter.getInitialState({})
};

export const getPastOrders = createAsyncThunk('pastOrders/getList',
  async ({ pageIndex = 1, pageSize = 10 }: { pageIndex?: number; pageSize?: number }) => {
    return await fetchPastOrdersListAPI(pageIndex, pageSize);
  });

const slice = createSlice({
  name: 'pastOrders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPastOrders.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(getPastOrders.fulfilled, (state, action) => {
        pastOrdersAdapter.setAll(state.orders, action.payload.orders);
        state.status = 'idle';
        state.paginationMeta = action.payload.meta;
      })
      .addCase(getPastOrders.rejected, (state) => {
        state.status = 'error';
      });
  },
});

export const pastOrdersSelectors = pastOrdersAdapter.getSelectors<RootState>(
  (state) => state.pastOrders.orders,
);

export const selectPastOrdersLoading = (state: RootState) => state.pastOrders.status;

export const selectPastOrdersPaginationMeta = (state: RootState) => state.pastOrders.paginationMeta;

export default slice.reducer;
