import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import {
  fetchCustomerPaymentCardsAPI,
  fetchCustomerPreferencesAPI,
  fetchDropoffTimeSlotsAPI,
  fetchPickupTimeSlotsAPI,
  createNewOrderAPI,
  addPaymentCardAPI,
  deletePaymentCardAPI,
  addPromoCodeAPI,
  fetchCustomerAddressesAPI,
  addCustomerAddressAPI,
  deleteCustomerAddressAPI,
  editCustomerAddressAPI,
  updateDefaultPaymentAPI,
} from 'api/customerAPI';
import { Cards } from 'api/types/cards.types';
import { Order } from 'api/types/order.types';
import { Error } from 'api/types/error.types';
import { Preferences } from 'api/types/preferences.types';
import { TimeSlots } from 'api/types/timeSlot.types';
import { Customer } from 'api/types/user.types';
import { RootState } from 'redux/reducers/rootReducer';
import { AddressForm } from 'schemas/address-schema';

export const confirmApplePayMethod = createAction('newOrder/setApplePayPaymentMethod');

/// thunks
export const fetchPickupAndDropoffTimeSlots = createAsyncThunk(
  'newOrder/fetchTimeSlots',
  async (config, thunkApi) => {
    const fetchPickupTimeslotsResult = await thunkApi.dispatch(fetchPickupTimeSlots());
    const { pickupTimeslotsList } = unwrapResult(fetchPickupTimeslotsResult);
    // await fetch pickup timeslots since dropff timeslots are dependent on pickup timeslots predefined pickup date

    const fetchDropoffTimeslotsResult = await thunkApi.dispatch(fetchDropoffTimeSlots());
    const { dropoffTimeslotsList } = unwrapResult(fetchDropoffTimeslotsResult);

    return { pickupTimeslotsList, dropoffTimeslotsList };
  },
);

export const fetchPickupTimeSlots = createAsyncThunk(
  'newOrder/fetchPickupTimeSlots',
  async (config, thunkApi) => {
    const {
      newOrder: { currentOrder },
      auth: { customer },
    } = thunkApi.getState() as RootState;

    const pickupTimeslotsConfig: TimeSlots.BaseProps = {
      addressId: currentOrder.addressId || '',
      orderType: currentOrder.orderType!,
    };

    const { pickupTimeslotsList } = await fetchPickupTimeSlotsAPI(pickupTimeslotsConfig);
    return { pickupTimeslotsList, isFirstOrder: !customer?.hasCompletedOrders };
  },
);

export const fetchDropoffTimeSlots = createAsyncThunk(
  'newOrder/fetchDropoffTimeSlots',
  async (config, thunkApi) => {
    const {
      newOrder: {
        currentOrder,
        timeSlots: { pickupTimeslotsData },
      },
      auth: { customer },
    } = thunkApi.getState() as RootState;

    const dropoffTimeslotsConfig: TimeSlots.DropoffProps = {
      addressId: currentOrder.addressId || '',
      orderType: currentOrder.orderType!,
      pickupDate:
        currentOrder.pickupTimeslot?.date ||
        pickupTimeslotsData?.presetDate ||
        pickupTimeslotsData?.predefinedDate ||
        '',
      isDropoffAtDoor: currentOrder.isDropoffAtDoor
    };

    const { dropoffTimeslotsList } = await fetchDropoffTimeSlotsAPI(dropoffTimeslotsConfig);
    return { dropoffTimeslotsList, isFirstOrder: !customer?.hasCompletedOrders };
  },
);

export const fetchOrderPreferences = createAsyncThunk(
  'newOrder/fetchOrderPreferences',
  async () => {
    const { customerPreferences } = await fetchCustomerPreferencesAPI();
    return customerPreferences;
  },
);

export const fetchCustomerPaymentCards = createAsyncThunk(
  'newOrder/fetchCustomerPaymentCards',
  async (config, thunkApi) => {
    const {
      auth: { customer },
    } = thunkApi.getState() as RootState;

    const { cards } = await fetchCustomerPaymentCardsAPI();
    return {
      cards,
      lastSelectedPaymentMethod: customer?.lastSelectedPaymentMethod,
      lastSelectedCardId: customer?.lastSelectedCardId,
    };
  },
);

export const createNewOrder = createAsyncThunk<
  {
    customerPreferences: Preferences.CustomerPreferences;
    newOrder: Order.Order;
    updatedUser: Customer.Customer;
  },
  Order.NewOrder,
  { rejectValue: Error.WashmenServerError }
>('newOrder/createNewOrder', async (payload: Order.NewOrder, { rejectWithValue }) => {
  try {
    const response = await createNewOrderAPI(payload);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateDefaultPaymentMethod = createAsyncThunk(
  'customer/updatePaymentMethod',
  async ({ method, id }: { method: Cards.PaymentMethods; id?: string }) => {
    await updateDefaultPaymentAPI(method, id);
    return {
      method,
      id,
    };
  },
);

export const addPaymentCard = createAsyncThunk<
  {},
  Cards.CardForm,
  { rejectValue: Error.WashmenServerError }
>('newOrder/addPaymentCard', async (payload: Cards.CardForm, thunkApi) => {
  const rejectWithValue = thunkApi.rejectWithValue;
  try {
    const response = await addPaymentCardAPI(payload);
    thunkApi.dispatch(fetchCustomerPaymentCards());
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deletePaymentCard = createAsyncThunk(
  'newOrder/deletePaymentCard',
  async (cardId: string, thunkApi) => {
    await deletePaymentCardAPI(cardId);
    thunkApi.dispatch(fetchCustomerPaymentCards());
  },
);

export const addPromoCode = createAsyncThunk<
  any,
  string,
  {
    rejectValue: Error.WashmenServerError;
  }
>('newOrder/addPromoCode', async (promocode, thunkApi) => {
  try {
    const response = await addPromoCodeAPI(promocode);
    return response;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data as Error.WashmenServerError);
  }
});

export const getCustomerAddresses = createAsyncThunk(
  'customer/fetchAddresses',
  async (config, thunkApi) => {
    const {
      auth: { customer },
    } = thunkApi.getState() as RootState;

    const { addresses } = await fetchCustomerAddressesAPI();
    return { addresses, isFirstOrder: !customer?.hasCompletedOrders };
  },
);

export const addCustomerAdddress = createAsyncThunk(
  'customer/addAddress',
  async (payload: AddressForm) => {
    return await addCustomerAddressAPI(payload);
  },
);

export const deleteCustomerAddress = createAsyncThunk(
  'customer/deleteAddress',
  async (addressId: string) => {
    const response = await deleteCustomerAddressAPI(addressId);
    return {
      updatedUser: response.updatedUser,
      addressId,
    };
  },
);

export const editCustomerAddress = createAsyncThunk(
  'customer/editAddress',
  async ({ addressForm, addressId }: { addressForm: AddressForm; addressId: string }) => {
    const response = await editCustomerAddressAPI(addressForm, addressId);
    return response;
  },
);
