import { Theme } from 'assets/themes/types';
import { useEffect } from 'react';
import WebFont from 'webfontloader';

export function useDynamicFonts(theme: Theme) {
  useEffect(() => {
    const fetchFont = () => {
      WebFont.load({
        google: {
          families: [theme.text?.webFontLoader || 'Ubuntu:300,400,500&display=swap'],
        },
      });
    };

    fetchFont();

    const body = document.querySelector('body');

    if (body && body.style && theme.text?.fontFamily) {
      body.style.fontFamily = theme.text.fontFamily;
    }
  }, [theme.text?.fontFamily, theme.text?.webFontLoader]);
}
