import i18n from 'i18n/config';
import React from 'react';
import ContentLoader from 'react-content-loader';

const OrderDetailsLoader: React.FC = (props) => {
    const isRTL = i18n.dir() === 'rtl';
    return (
        <ContentLoader
            speed={0.8}
            width="100%"
            height="900"
            // viewBox="0 0 375 818"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            rtl={isRTL}
            {...props}
        >
            <rect x="20" y="60" rx="12" ry="12" width="110" height="25" />
            <rect x="25" y="114" rx="6" ry="6" width="86%" height="105" />

            <rect x="25" y="255" rx="100" ry="100" width="37" height="37" />
            <rect x="25" y="330" rx="100" ry="100" width="37" height="37" />
            <rect x="25" y="407" rx="100" ry="100" width="37" height="37" />
            <rect x="25" y="547" rx="100" ry="100" width="37" height="37" />
            <rect x="25" y="663" rx="100" ry="100" width="37" height="37" />
            <rect x="25" y="735" rx="100" ry="100" width="37" height="37" />

            <rect x="75" y="260" rx="1" ry="1" width="50" height="10" />
            <rect x="75" y="280" rx="1" ry="1" width="80" height="10" />
            <rect x="75" y="335" rx="1" ry="1" width="100" height="10" />
            <rect x="75" y="355" rx="1" ry="1" width="190" height="10" />
            <rect x="75" y="410" rx="1" ry="1" width="110" height="10" />
            <rect x="75" y="430" rx="1" ry="1" width="200" height="10" />
            <rect x="0" y="470" rx="1" ry="1" width="100%" height="7" />

            <rect x="75" y="508" rx="1" ry="1" width="120" height="10" />
            <rect x="75" y="530" rx="1" ry="1" width="150" height="10" />
            <rect x="75" y="550" rx="1" ry="1" width="120" height="10" />
            <rect x="75" y="570" rx="1" ry="1" width="100" height="10" />
            <rect x="75" y="595" rx="1" ry="1" width="200" height="10" />
            <rect x="75" y="615" rx="1" ry="1" width="220" height="10" />
            <rect x="75" y="675" rx="1" ry="1" width="200" height="10" />
            <rect x="75" y="735" rx="1" ry="1" width="200" height="10" />
            <rect x="75" y="755" rx="1" ry="1" width="200" height="10" />

        </ContentLoader>
    );
}
export default OrderDetailsLoader;
