import React from 'react';
import ContentLoader from 'react-content-loader';
import i18n from 'i18n/config';

const ServiceTabLoader: React.FC = (props) => {
  const isRTL = i18n.dir() === 'rtl';
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={38}
      // viewBox="0 0 476 180"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      rtl={isRTL}
      {...props}
    >
      <rect x="0" y="0" rx="20" ry="20" width="132" height="30" />
      <rect x="145" y="0" rx="20" ry="20" width="132" height="30" />
      <rect x="290" y="0" rx="20" ry="20" width="132" height="30" />
      <rect x="435" y="0" rx="20" ry="20" width="132" height="30" />
    </ContentLoader>
  );
};
export default ServiceTabLoader;
