import i18n from 'i18n/config';
import React from 'react';
import ContentLoader from 'react-content-loader';

const OrdersListLoader: React.FC = (props) => {
  const isRTL = i18n.dir() === 'rtl';

  return (
    <ContentLoader
      speed={0.5}
      width="100%"
      height={83}
      // viewBox="0 0 476 83"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      rtl={isRTL}
      {...props}
    >
      <rect x="0" y="33" rx="0" ry="0" width="82" height="16" />
      <rect x="0" y="68" rx="0" ry="0" width="252" height="15" />
    </ContentLoader>
  );
};
export default OrdersListLoader;
