import React from 'react';
import Lottie from 'lottie-web';
import cx from 'classnames';
import { useDynamicAnimations } from 'assets/animations';

interface Props {
  centered?: boolean;
  className?: any;
  isDelayed?: boolean;
}

const SpinnerLoader: React.FC<Props> = ({ centered = true, className, isDelayed = false }) => {
  const { spinnerAnimation } = useDynamicAnimations();

  const elRef = React.useRef<HTMLDivElement>(null);

  const initLottie = React.useCallback(() => {
    if (!elRef.current) return;
    return Lottie.loadAnimation({
      container: elRef.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: !isDelayed,
      animationData: spinnerAnimation,
      rendererSettings: {},
    });
  }, [spinnerAnimation, isDelayed]);

  React.useEffect(() => {
    if (!spinnerAnimation) {
      return;
    }
    const lottie = initLottie();

    let timeout: NodeJS.Timeout;
    if (isDelayed) {
      timeout = setTimeout(() => lottie?.play(), 1000);
    }

    return () => {
      lottie?.destroy();
      clearTimeout(timeout);
    };
  }, [initLottie, spinnerAnimation, isDelayed]);

  return (
    <div
      className={cx(className, {
        'centered-element': centered,
      })}
      ref={elRef}
    />
  );
};

export default SpinnerLoader;
