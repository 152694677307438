import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { cancelActiveOrderAPI, fetchActiveOrdersListAPI } from 'api/customerAPI';
import { Order } from 'api/types/order.types';
import { createNewOrder } from 'pages/newOrder/newOrder.thunks';
import { RootState } from 'redux/reducers/rootReducer';

const activeOrdersAdapter = createEntityAdapter<Order.Order>({
  selectId: (order) => order.id,
});

export const getActiveOrders = createAsyncThunk('activeOrders/getList', async () => {
  return await fetchActiveOrdersListAPI();
});

export const cancelActiveOrder = createAsyncThunk(
  'activeOrders/cancelOrder',
  async ({ orderId, reason }: any) => {
    return await cancelActiveOrderAPI(orderId, reason);
  },
);

const slice = createSlice({
  name: 'activeOrders',
  initialState: activeOrdersAdapter.getInitialState({
    status: 'idle',
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActiveOrders.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(getActiveOrders.fulfilled, (state, action) => {
        activeOrdersAdapter.setAll(state, action.payload);
        state.status = 'idle';
      })
      .addCase(getActiveOrders.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(createNewOrder.fulfilled, (state, action) => {
        activeOrdersAdapter.addOne(state, action.payload.newOrder);
      });
  },
});

export const activeOrdersSelectors = activeOrdersAdapter.getSelectors<RootState>(
  (state) => state.activeOrders,
);

export const selectActiveOrdersLoading = (state: RootState) => state.activeOrders.status;

export default slice.reducer;
