import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit';
import Interceptor from 'api/api.interceptor';
import { authMiddleware, uiMiddleware, reportingMiddleware } from './middleware/';
import rootReducer, { RootState } from './reducers/rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authMiddleware, uiMiddleware, reportingMiddleware),
});

// add axios interceptors
Interceptor(store);

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;

export default store;
