import MessagingService from 'wrappers/messaging';

function useMessaging() {
  return {
    show: () => MessagingService.getInstance().show(),
    update: (settings: Intercom_.IntercomSettings) =>
      MessagingService.getInstance().update(settings),
  };
}

export default useMessaging;
