import React from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import store from 'redux/store';
import { useDispatch } from 'react-redux';
import { fetchCustomer, selectLoginState } from 'redux/slices/authSlice';
import { ConfirmationDialogProvider } from 'components/confirmationModal/confirmationDialog';
import ScrollToTop from 'components/scrollToTop';
import ServiceWorkerWrapper from 'components/ServiceWorkerWrapper';
import { usePartner } from 'hooks/usePartner';
import { listCarouselData, updateAppLanguage } from 'redux/slices/uiSlice';
import { trackEvent } from 'wrappers/reporting';
import { useTheme } from 'assets/themes';
import { ThemeProvider } from 'styled-components';
import { useDynamicIcons } from 'assets/icons';
import { useDynamicFonts } from 'assets/fonts';
import useImagePreloader from 'hooks/useImagePreloader';
import { SpinnerLoader } from 'components/loader';
import { AnimatePresence } from 'framer-motion';
import AnimatedLayout from 'components/AnimatedLayout';
import ConfigProvider from 'context/app-config';
import useIntercomTheme from 'hooks/useIntercomTheme';

// lazy load pages
const Home = React.lazy(() => import('pages/home'));
const NewOrder = React.lazy(() => import('pages/newOrder'));
const Pricing = React.lazy(() => import('pages/pricing'));
const Support = React.lazy(() => import('pages/support/support'));
const Orders = React.lazy(() => import('pages/orders'));
const OrderDetails = React.lazy(() => import('pages/orderDetails'));
const Profile = React.lazy(() => import('pages/profile'));
const Verify = React.lazy(() => import('pages/verify'));

// TODO: service type regex to be dynamic
function App() {
  return (
    <Provider store={store}>
      <RouterWrapper />
    </Provider>
  );
}

const RouterWrapper: React.FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectLoginState);
  useImagePreloader();

  const theme = useTheme();
  useDynamicIcons();
  useDynamicFonts(theme);

  React.useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchCustomer());
    }
    // setup language settings for the app (req header etc)
    dispatch(updateAppLanguage());
    dispatch(listCarouselData());
  }, [dispatch, isLoggedIn]);

  return (
    <ThemeProvider theme={theme}>
      <div className="text-start">
        <ConfirmationDialogProvider>
          <ServiceWorkerWrapper />
          <ToastContainer hideProgressBar position="top-center" />
          <ConfigProvider>
            <React.Suspense fallback={<SpinnerLoader isDelayed />}>
              <Router>
                <AppSwitch />
              </Router>
            </React.Suspense>
          </ConfigProvider>
        </ConfirmationDialogProvider>
      </div>
    </ThemeProvider>
  );
};

function usePageViews() {
  let location = useLocation();
  React.useEffect(() => {
    trackEvent(`Viewed ${location.pathname} Page`);
  }, [location]);
}

function AppSwitch() {
  let location = useLocation();
  usePageViews();
  usePartner(); // get partner name from subdomain or query param
  useIntercomTheme();

  return (
    <React.Fragment>
      <ScrollToTop />
      <AnimatePresence>
        <Switch location={location} key={location.pathname}>
          <Route
            path="/home"
            render={() => (
              <AnimatedLayout>
                <Home />
              </AnimatedLayout>
            )}
          />
          <Route
            path="/support"
            render={() => (
              <AnimatedLayout>
                <Support />
              </AnimatedLayout>
            )}
          />
          <Route
            path="/pricing/:type(WASH_FOLD|HOME_CARE|CLEAN_PRESS|PRESS|SHOE_CLEANING)"
            exact
            render={() => (
              <AnimatedLayout>
                <Pricing />
              </AnimatedLayout>
            )}
          />
          <Route
            path="/newOrder"
            render={() => (
              <AnimatedLayout>
                <NewOrder />
              </AnimatedLayout>
            )}
          />
          <Route
            path="/orders"
            render={() => (
              <AnimatedLayout>
                <Orders />
              </AnimatedLayout>
            )}
          />
          <Route
            path="/order/details/:orderType(active|past)/:orderId"
            exact
            render={(route) => (
              <AnimatedLayout>
                <OrderDetails
                  type={route.match?.params.orderType}
                  orderId={route.match?.params.orderId}
                />
              </AnimatedLayout>
            )}
          />
          <Route
            path="/profile"
            render={() => (
              <AnimatedLayout>
                <Profile />
              </AnimatedLayout>
            )}
          />
          <Route exact path="/verify/:token" render={() => <Verify />} />
          <Route exact path="/verify" render={() => <Verify />} />
          <Redirect to="/home" />
          <Redirect from="/reload" to="/home" />
        </Switch>
      </AnimatePresence>
    </React.Fragment>
  );
}

export default App;
