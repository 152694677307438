import { RootState } from 'redux/reducers/rootReducer';
import { createSlice, createAsyncThunk, createSelector, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchCustomerAPI,
  loginAPI,
  logoutAPI,
  refreshTokenAPI,
  registerUserAPI,
  RegisterUserInputs,
  sendVerificationCodeAPI,
  updateUserAPI,
  UpdateUserInputs,
  verifyCodeAPI,
  verifyAuthTokenAPI,
  editPhoneNumberAPI,
  updateLanguageAPI,
} from 'api/customerAPI';
import { Customer } from 'api/types/user.types';
import { addOrderPaymentCard } from 'pages/orderDetails/orderDetails.slice';
import {
  addPromoCode,
  confirmApplePayMethod,
  createNewOrder,
  deleteCustomerAddress,
} from 'pages/newOrder/newOrder.thunks';
import { cancelActiveOrder } from 'pages/orders/modules/activeOrders/activeOrders.slice';
import { WASHMEN_CUSTOMER_API } from 'api/config';
import { setSelectedPaymentCard } from 'pages/newOrder/newOrder.slice';
import { checkApplyPayCompatibility } from 'wrappers/apple_pay';
import { setUserProfile } from 'wrappers/reporting';
import { LanguageCodes } from 'i18n';
import { isAxiosError } from 'api/config';
import { INTEGRATION_PARTNERS } from 'config/types';
import { identifyUser } from 'lib/hotjar';

export const sendVerificationCode = createAsyncThunk(
  'auth/sendVerificationCode',
  async (phone: string) => {
    return await sendVerificationCodeAPI(phone);
  },
);

export const verifyOTP = createAsyncThunk(
  'auth/verifyOTP',
  async ({ phone, code }: { code: string; phone: string }) => {
    return (await verifyCodeAPI(code, phone)).data;
  },
);

export const login = createAsyncThunk(
  'auth/login',
  async ({
    phone,
    code,
    email,
    thirdPartyId,
    authTrackId,
    customOriginApp,
    language,
  }: {
    code: string;
    phone: string;
    email: string;
    thirdPartyId?: string;
    authTrackId?: string;
    customOriginApp?: string;
    language?: LanguageCodes;
  }) => {
    const loginAPIA = await loginAPI(
      code,
      phone,
      email,
      thirdPartyId,
      authTrackId,
      customOriginApp,
      language,
    );
    return loginAPIA;
  },
);

export const refreshToken = createAsyncThunk('user/refreshToken', async (payload, ThunkApi) => {
  try {
    return await refreshTokenAPI();
  } catch (e) {
    await ThunkApi.dispatch(logout());
    return ThunkApi.rejectWithValue('invalid session');
  }
});

export const registerUser = createAsyncThunk<
  Customer.LoginRootResponse,
  RegisterUserInputs,
  { rejectValue: string | undefined }
>('user/register', async (data: RegisterUserInputs, ThunkApi) => {
  try {
    return await registerUserAPI(data);
  } catch (e) {
    if (isAxiosError(e)) {
      return ThunkApi.rejectWithValue(
        e.response?.data?.infoCode?.message || e.response?.data?.message,
      );
    } else return ThunkApi.rejectWithValue('server error' as string);
  }
});

export const verifyToken = createAsyncThunk('client/verify', async (token: string) => {
  return await verifyAuthTokenAPI(token);
});

export const logout = createAsyncThunk('auth/logout', async (payload, ThunkAPI) => {
  const store = ThunkAPI.getState() as RootState;
  const { authToken, refreshToken } = store.auth;
  if (!authToken || !refreshToken) {
    ThunkAPI.rejectWithValue('failed to logout');
  } else {
    return await logoutAPI(authToken, refreshToken);
  }
});

export const updateUser = createAsyncThunk('user/update', async (payload: UpdateUserInputs) => {
  return await updateUserAPI(payload);
});

export const updateUserLanguage = createAsyncThunk(
  'user/update/language',
  async (payload: LanguageCodes) => {
    return await updateLanguageAPI({ language: payload });
  },
);

export const editPhoneNumber = createAsyncThunk(
  'user/editPhoneNumber',
  async (payload: { oldPhone: string; phone: string; tempCode: string }) => {
    const { oldPhone, phone, tempCode } = payload;
    return await editPhoneNumberAPI(oldPhone, phone, tempCode);
  },
);

export const fetchCustomer = createAsyncThunk('auth/fetchCustomer', async () => {
  const { user } = await fetchCustomerAPI();
  return user;
});

interface State {
  customer?: Customer.Customer;
  refreshToken?: string;
  authToken?: string;
  partnerId: INTEGRATION_PARTNERS | null;
}

/**
 * @description fetches initial state from local storage if found
 */
const getInitialState: () => State = () => {
  const user = localStorage.getItem('user') as string | null;
  const refreshToken = localStorage.getItem('refreshToken') || '';
  const token = localStorage.getItem('token') || '';
  const partnerId = (sessionStorage.getItem('partner_id') as INTEGRATION_PARTNERS) || null;
  if (token) {
    WASHMEN_CUSTOMER_API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  if (user) {
    const customer: Customer.Customer = JSON.parse(user);
    //Error Reporting

    const userData = {
      id: customer.id,
      email: customer.email,
      name: `${customer.firstName} ${customer.lastName}`,
    };

    setUserProfile(userData);
    identifyUser(userData);
  }

  return {
    customer: user ? JSON.parse(user) : undefined,
    refreshToken,
    authToken: token,
    ...(partnerId && { partnerId }),
  };
};

const initialState: State = getInitialState();

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPartnerId: (state, action: PayloadAction<INTEGRATION_PARTNERS>) => {
      state.partnerId = action.payload;
    },
    setAuth: (state, action: PayloadAction<Customer.LoginRootResponse>) => {
      const { authToken, refreshToken, user } = action.payload;
      state.authToken = authToken;
      state.refreshToken = refreshToken;
      state.customer = user;
    },
  },
  extraReducers: (builder) => {
    function sharedAuthUserReducer(
      state: State,
      action: PayloadAction<Customer.LoginRootResponse>,
    ) {
      const { authToken, refreshToken, user } = action.payload;
      state.authToken = authToken;
      state.refreshToken = refreshToken;
      state.customer = user;
    }

    function sharedClearUserReducer(state: State) {
      state.authToken = '';
      state.refreshToken = '';
      state.customer = undefined;
    }
    builder
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.customer = action.payload;
      })
      .addCase(createNewOrder.fulfilled, (state, action) => {
        state.customer = action.payload.updatedUser;
      })
      .addCase(cancelActiveOrder.fulfilled, (state, action) => {
        state.customer = action.payload.updatedUser;
      })
      .addCase(addOrderPaymentCard.fulfilled, (state, action) => {
        if (action.payload?.user) {
          state.customer = action.payload.user;
        }
      })
      .addCase(deleteCustomerAddress.fulfilled, (state, action) => {
        state.customer = action.payload.updatedUser;
      })
      .addCase(login.fulfilled, sharedAuthUserReducer)
      .addCase(registerUser.fulfilled, sharedAuthUserReducer)
      .addCase(refreshToken.fulfilled, sharedAuthUserReducer)
      .addCase(verifyToken.fulfilled, sharedAuthUserReducer)
      .addCase(logout.fulfilled, sharedClearUserReducer)
      .addCase(refreshToken.rejected, sharedClearUserReducer)
      .addCase(updateUser.fulfilled, (state, action) => {
        state.customer = action.payload.user;
      })
      .addCase(editPhoneNumber.fulfilled, (state, action) => {
        state.customer = action.payload;
      })
      .addCase(confirmApplePayMethod, (state) => {
        if (state.customer) {
          state.customer.lastSelectedPaymentMethod = 'APPLE_PAY';
        }
      })
      .addCase(setSelectedPaymentCard, (state, action) => {
        if (state.customer) {
          state.customer.lastSelectedPaymentMethod = 'CARD';
          state.customer.lastSelectedCardId = action.payload;
        }
      })
      .addCase(updateUserLanguage.fulfilled, (state, action) => {
        state.customer = action.payload.user;
      })
      .addCase(addPromoCode.fulfilled, (state, action) => {
        if (state.customer) {
          state.customer.credit = action.payload.data.updatedUser.credit;
        }
      });
  },
});

export const { setPartnerId, setAuth } = slice.actions;

export const selectCustomer = (state: RootState) => state.auth.customer;

export const selectLoginState = (state: RootState) => Boolean(state.auth.customer);

export const selectActiveOrdersCount = createSelector(selectCustomer, (state) => {
  if (!state) return 0;
  return state.activeOrdersCount;
});

export const selectCompletedOrdersCount = createSelector(selectCustomer, (state) => {
  if (!state) return 0;
  return state.completedOrdersCount;
});

export const selectOrderToRate = createSelector(selectCustomer, (state) => {
  if (!state || !state.orderToRate) return '';
  return state.orderToRate;
});

export const selectHasPendingOrders = createSelector(selectCustomer, (state) => {
  if (!state || !state.pendingOrders) return false;
  return state.pendingOrders.length > 0;
});

export const hasApplePayPayment = createSelector(selectCustomer, (customer) => {
  if (!customer) return false;
  if (customer.lastSelectedPaymentMethod === 'APPLE_PAY' && checkApplyPayCompatibility()) {
    return true;
  }
  return false;
});

export const selectIsFirstTimeCustomer = createSelector(selectCompletedOrdersCount, (state) => {
  return state === 0;
});

export const selectPartner = (state: RootState) => {
  if (process.env.NODE_ENV === 'development') {
    return INTEGRATION_PARTNERS.CAREEM;
  }

  return state.auth.partnerId;
};

export default slice.reducer;
