import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

interface UserData {
  id: string;
  name: string;
  email: string;
}
interface Window {
  analytics: any;
}

declare var window: Window;

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY || '',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production'],
});

export const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

export const notifyError = (error: string) => Bugsnag.notify(new Error(error));
export const notifyErrorObject = (error: Error) => Bugsnag.notify(error);

export const trackEvent = (name: string, properties?: object) => window.analytics.track(name, properties);

export const setUserProfile: (data: UserData) => void = (data) => {
  window.analytics.identify(data.id, {
    name: data.name,
    email: data.email
  });
  Bugsnag.setUser(data.id, data.email, data.name);
}

export const clearUserProfile = () => {
  window.analytics.reset();
  Bugsnag.setUser()
};

