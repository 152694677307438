import React from 'react';
import { motion } from 'framer-motion';

const pageVariants = {
  initial: {
    transition: { duration: 0 },
    opacity: 0,
  },
  in: {
    transition: { duration: 0 },
    opacity: 1,
  },
  out: {
    transition: { duration: 0 },
    opacity: 0,
  },
};

const AnimatedLayout: React.FC = ({ children }) => {
  return (
    <motion.div initial="initial" animate="in" exit="out" variants={pageVariants}>
      {children}
    </motion.div>
  );
};

export default AnimatedLayout;
