import { Middleware } from '@reduxjs/toolkit';
import { WASHMEN_CUSTOMER_API } from 'api/config';
import i18n from 'i18n/config';
import { logout, refreshToken, updateUserLanguage } from 'redux/slices/authSlice';
import { updateAppLanguage, setAppLanguage } from 'redux/slices/uiSlice';
import { getBrowserLanguage } from 'utils/browserLanguage';

export const uiMiddleware: Middleware = (store) => (next) => (action) => {
  switch (action.type) {
    case refreshToken.rejected.type:
    case logout.fulfilled.type: {
      store.dispatch(setAppLanguage(getBrowserLanguage()));
      localStorage.removeItem('language');
      next(action);
      break;
    }
    case updateAppLanguage.toString(): {
      let { language } = action.payload;
      const { customer } = store.getState().auth;
      const { appLanguage } = store.getState().ui;
      const shouldUpdateOnServer =
        Boolean(language && customer) || Boolean(customer && !customer.language);
      if (!language) {
        language = appLanguage;
        if (customer && customer.language) {
          language = customer.language;
        }
      }

      if (shouldUpdateOnServer) {
        // @ts-ignore
        store.dispatch(updateUserLanguage(language));
      }

      const htmlTag = document.querySelector('html');
      const bodyTag = document.querySelector('body');
      localStorage.setItem('language', language);

      WASHMEN_CUSTOMER_API.defaults.headers.common['Accept-Language'] = language;

      i18n.changeLanguage(language, (err, t) => {
        if (err) return console.log('something went wrong loading', err);
      });

      if (htmlTag && bodyTag) {
        htmlTag.lang = language;
        bodyTag.dir = i18n.dir();
      }

      next(action);
      break;
    }

    default:
      next(action);
  }
};

export default uiMiddleware;
